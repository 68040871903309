import Compressor from "compressorjs";

export function singleImageCompressor(file, fileResult) {
    if (!file) {
        return;
    }
    new Compressor(file, {
        quality: 0.6,
        success(result) {
            const reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onload = () => {
                fileResult.pop();
                fileResult.push({
                    id: 0,
                    base64: reader.result,
                    path: null
                });
            };
        },
        error(err) {
            console.log(err.message);
            return false;
        }
    });
}
export function multiImageCompressor(files, fileResult) {
    if (!files) {
        return;
    }
    files.forEach(file => {
        new Compressor(file, {
            quality: 0.6,
            success(result) {
                const reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onload = () => {
                    fileResult.push({
                        id: 0,
                        base64: reader.result,
                        description: "", 
                        path: null,
                        state: 1
                    });
                };
            },
            error(err) {
                console.log(err.message);
                return false;
            }
        });
    });
}
export function deleteImage(index, filesInput) {
    const state = filesInput[index].state;
    if(state == 1)
    {
        filesInput.splice(index, 1);
    }
    else if(state == 0) {
        filesInput[index].state = 2;
    }
}